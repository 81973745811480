var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"750","max-width":"100%","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"mx-auto",class:{
      'w-agromousquetaire-form-modal': true,
      'w-agromousquetaire-form-modal--mobile': _vm.$voicer.isMobile,
    }},[_c('v-toolbar',{staticClass:"color: #fbde51",attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.open = false}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-close-thick")])],1)],1),_vm._v(" "),_c('v-img',{attrs:{"src":_vm.$voicer.isMobile
          ? require('~/assets/images/popupagrom.png')
          : require('~/assets/images/popupagrom.png'),"alt":"image emission","max-height":_vm.$voicer.isMobile === false ? 320 : undefined}}),_vm._v(" "),_c('v-card-text',{staticClass:"w-agromousquetaire-form-modal__body2",class:{
        'pa-2 title': _vm.$voicer.isMobile === false,
        'pa-1 headline': _vm.$voicer.isMobile === true,
      }},[_vm._v("\n      Découvrez les podcasts en direct de la réunion Top 130 "),_c('br'),_vm._v("\n      les 15 et 16 novembre derniers à St Malo !\n    ")]),_vm._v(" "),_c('div',{staticClass:"body-2"},[_c('v-btn',{attrs:{"color":"primary","href":"/universe/5ef0b11b4fc7ef4090cc1433","target":"_blank","rounded":"","depressed":""}},[_vm._v("Découvrez les podcasts\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }